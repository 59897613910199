import React from "react";
import htp1 from "../../assets/png/htp1.png";
import htp2 from "../../assets/png/htp2.png";
import htp3 from "../../assets/png/htp3.png";
import htp4 from "../../assets/png/htp4.png";
import bulb from "../../assets/png/lightbulb 1.png";

const HowToPlay = () => {
  return (
    <section className="home-how-to-play">
      <div className="title">
        <img className="bulb-icon" src={bulb} />
        <h1>How To Play</h1>
      </div>
      <div className="play-list">
        <Card
          number={1}
          src={htp1}
          label="Select Challenge"
          className="orange-gredient"
        />
        <Card
          number={2}
          src={htp3}
          label="Create Team of 11 out of 22 Stocks"
          className="pink-gredient"
        />
        <Card
          number={3}
          src={htp4}
          label="Add Money and Join Contest"
          className="blue-gredient"
        />
        <Card
          number={4}
          src={htp2}
          label="Earn upto 1,00,000"
          className="yellow-gredient"
        />
      </div>
    </section>
  );
};

export default HowToPlay;

const Card = ({ src, number, label, className }) => (
  <div className={"how-to-play-card " + className}>
    <div className="number">{number}</div>
    <img src={src} alt="icon" />
    <div className="desc">{label}</div>
    <div className=""></div>
  </div>
);
