import React from "react";
import Header from "../../common/Header";

const Privacy = () => {
    return (
        <>
            <Header/>
            <section className="stocky-home">
                <h1>Privacy Policy</h1>
                <p style={{'color': "#fff"}}>
                    Revolute Edtech Private Limited operates the portal in India, which offers Stock Market based
                    fantasy games through the web-portal stocksy.app and partner website(s) and mobile application(s)
                    (collectively referred to as the “Portal”) (Revolute Edtech Private Limited referred to herein as
                    “Stocksy” or “Revolute” or “we” or “us” “our”).

                    Any person utilizing the Portal ("User" or "you" or "your") or any of its features, including
                    participation in the various contests, games (including fantasy games ("Game") (" Services ") being
                    conducted on the Portal) shall be bound by this Privacy Policy.

                    Stocksy respects the privacy of its Users and is committed to protecting it in all respects. With a
                    view to offering an enriching and holistic internet experience to its Users, Stocksy offers a vast
                    repository of Services. Most of the Services are offered for free, but you may need registration to
                    participate in Stocksy's online games. The information about the User is collected by Stocksy as (i)
                    information supplied by the User and (ii) information automatically tracked during User's navigation
                    on Stocksy.

                    Before you submit any information to the Portal, please read this Privacy Policy for an explanation
                    of how we will treat your personal information. By using any part of the Portal, you consent to the
                    collection, use, disclosure, and transfer of your personal information for the purposes outlined in
                    this Privacy Policy and to the collection, processing, and maintenance of this information. If you
                    do not agree to this Privacy Policy, please do not use the Portal. Your use of any part of the
                    Portal indicates your acceptance of this Privacy Policy and of the collection, use, and disclosure
                    of your personal information in accordance with this Privacy Policy. While you have the option not
                    to provide us with certain information, withdraw your consent to collect certain information,
                    request temporary suspension of the collection of information, or request deletion of information
                    collected, kindly note that in such an event, you may not be able to take full advantage of the
                    entire scope of features and services offered to you and Stocksy reserves the right not to provide
                    you with our services.
                </p>
                <h2>Purpose and Usage:</h2>
                <p>
                    To avail of certain Services on the Portal, Users would be required to provide certain information
                    for the registration process, namely:
                    1. Name
                    2. Mobile No
                    3. Email address
                    4. Date of birth

                    In the course of providing you with access to the Services, and in order to provide you access to
                    the features offered through the Portal to verify your identity and secure your account details, you
                    may give us the permission to capture, record your device information, operating system information,
                    network information, location information. You may also be required to furnish additional
                    information, including your Permanent Account Number.

                    In certain instances, we may also collect Financial Information (“FI”) from you on the Portal. FI
                    means financial information, such as information regarding the payment instrument/modes used by you
                    to make such payments, which may include cardholder name, credit/debit card number (in encrypted
                    form) with the expiration date, banking details, wallet details, etc. This information is presented
                    to you at the time of making a payment to enable you to complete your payment expeditiously.

                    Any FI collected by Stocksy shall not be disclosed to any third party without your express consent,
                    save as otherwise set out in this Privacy Policy or as provided in a separate written agreement
                    between Stocksy and you or as required by law. It is clarified that this condition shall not apply
                    to the publicly available information.

                    In the course of providing the Services, Users may invite other existing Users or other users
                    ("Invited Users") to participate in any of the Services by providing the email address or Mobile
                    Number of such users. Stocksy may thereafter use this information to contact the Invited User and
                    invite such users to register with Stocksy (if such Invited User is not an existing User) and
                    participate in the Game in relation to which such person was invited by the User. The participation
                    of the Invited User in any of the Game shall be subject to the terms of this Privacy Policy and the
                    Terms and Conditions for the use of the Portal. The User hereby represents that the Invited Users
                    have consented and agreed to such disclosure to and use of their email address and Mobile Number by
                    Stocksy.

                    All required information is specific and based on the kind of Game/ Services the User wishes to
                    participate in or access and will be utilized for the purpose of providing services, including but
                    not limited to the Services requested by the User. The information supplied by the Users enables us
                    to improve the Services and provide you with the most user-friendly game experience.

                    Stocksy may also share such information with affiliates and third parties in limited circumstances,
                    including for the purpose of providing services requested by the User, complying with legal
                    processes, preventing fraud or imminent harm, and ensuring the security of our network and services.
                </p>
                <h2>Disclosure/Sharing:</h2>
                <p>
                    Stocksy may also share information as provided by you and data concerning usage of the Services and
                    participation in the Games with third-party service providers engaged by Stocksy, for the purpose of
                    data analytics or other similar purposes, for the purpose of storage, improving the services and
                    helping Stocksy serve you better.

                    Where we propose to use your personal information (that is, information that may be used to identify
                    the User and that is not otherwise publicly available) for any other uses, we will ensure that we
                    notify you first. You will also be given the opportunity to withhold or withdraw your consent for
                    your use other than as listed above.

                    By using the Portal, you hereby expressly agree and grant consent to the collection, use, and
                    storage of this information by Stocksy. Stocksy reserves the right to share, disclose and transfer
                    information collected hereunder with its own affiliates. In the event Stocksy sells or transfers all
                    or a portion of its business assets, consumer information may be one of the business assets that are
                    shared, disclosed, or transferred as part of the transaction. You hereby expressly grant consent and
                    permission to Stocksy for the disclosure and transfer of information to such third parties. Stocksy
                    may share information as provided by you and data concerning usage of the Services and participation
                    in the Game with its commercial partners for the purpose of facilitating user engagement, marketing,
                    promotional purposes, and other related purposes. Further, Stocksy reserves the right to disclose
                    personal information as obligated by law in response to duly authorized legal processes and
                    governmental requests and as necessary to protect the rights and interests of Stocksy.
                </p>
                <h2>Use of Cookies:</h2>
                <p>
                    To improve the effectiveness and usability of the Portal for our Users, we use "cookies" or similar
                    electronic tools to collect information to assign each visitor a unique random number as a User
                    Identification (User ID) to understand the User's individual interests using the identified device.
                    Unless the User voluntarily identifies himself/herself (e.g., through registration), Stocksy has no
                    way of knowing who the User is, even if we assign a cookie to the User's device. The only personal
                    information a cookie can contain is information supplied by the User. A cookie cannot read data off
                    the User's hard drive. Stocksy’s advertisers may also assign their own cookies to the User's browser
                    (if the User clicks on their ad banners), a process that Stocksy does not control.
                    Stocksy's web servers automatically collect limited information about the User's device's connection
                    to the Internet, including the User's IP address, when the User visits the Portal. (User's IP
                    address is a number that lets devices attached to the Internet know where to send data to the User
                    -- such as the web pages viewed by the User). The User's IP address does not identify the User
                    personally. Stocksy uses this information to deliver its web pages to Users upon request, to tailor
                    its Portal to the interests of its users, to measure traffic within the Portal, and let advertisers
                    know the geographic locations from where Stocksy's visitors come.
                </p>
                <h2>Security Procedures:</h2>
                <p>
                    All information gathered on Stocksy is securely stored within Stocksy- a controlled database. The
                    database is stored on servers secured behind a firewall; access to such servers is
                    password-protected and strictly limited based on a need-to-know basis. However, we understand that
                    as effective as our security measures are, no security system is impenetrable. Thus, we cannot
                    guarantee the security of our database, nor can we guarantee that information you supply will not be
                    intercepted while being transmitted to us over the Internet. Further, any information you include in
                    a posting to the discussion areas will be available to anyone with Internet access. By using the
                    Portal, you understand and agree that your information may be used in or transferred to countries
                    other than India.
                    Stocksy also believes that the internet is an ever-evolving medium. We may periodically review from
                    time to time and change our privacy policy to incorporate future changes that may be considered
                    appropriate without any notice to you. Our use of any information we gather will always be
                    consistent with the policy under which the information was collected, regardless of what the new
                    policy may be. Any changes to our privacy policy will be posted on this page, so you are always
                    aware of what information we collect, how we use it, how we store it, and under what circumstances
                    we disclose it.
                </p>
                <h2>Advertising:</h2>
                <p>When Stocksy presents information to its online advertisers -- to help them understand our audience
                    and confirm the value of advertising on the Portal -- it is usually in the form of aggregated
                    statistics on traffic to various pages within our site. When you register with Stocksy, we contact
                    you from time to time about updating your content to provide features that we believe may benefit
                    you.

                    Several deceptive emails, websites, blogs, etc., claiming to be from or associated with Stocksy may
                    or are circulating on the Internet. These emails, websites, blogs, etc., often include our logo,
                    photos, links, content, or other information. Some emails, websites, blogs, etc. call the user
                    requesting the user to provide login name, password, etc., or inform the user that the user has won
                    a prize/ gift or provide a method to commit illegal/ unauthorized act or deed or request detailed
                    personal information or a payment of some kind. The sources and contents of these emails, websites,
                    blogs, etc., and accompanying materials are in no way associated with Stocksy. For your own
                    protection, we strongly recommend not responding to such emails or using these websites, blogs, etc.
                    We may use the information provided by you to Stocksy, including your email address or phone number,
                    to contact you about the Services availed by you or to inform you of our updated Services, if any.
                </p>

                <h2>Conditions of Use:</h2>
                <p>STOCKSY DOES NOT WARRANT THAT THIS PORTAL, ITS SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE
                    VIRUS FREE. STOCKSY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS
                    PORTAL, INCLUDING, BUT NOT LIMITED TO, COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE,
                    SPECIAL, AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS OPPORTUNITY, INCOME OR PROFIT,
                    LOSS OF OR DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES. IN NO EVENT WILL STOCKSY BE LIABLE FOR
                    ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN AMOUNT OF INR 100.
                </p>
                <h2>Retention of Data:</h2>
                <p>Your personal information may be retained and may continue to be used until (i) the relevant
                    purposes for the use of your information described in this Privacy Policy are no longer applicable,
                    and (ii) we are no longer required by applicable law, regulations, contractual obligations or
                    legitimate business purposes to retain your personal information and the retention of your personal
                    information is not required for the establishment, exercise or defense of any legal claim.
                </p>
                <h2>User Account and Data Deletion:</h2>
                <p>Users are entitled to request Stocksy to delete their User accounts and their personal information
                    by sending an email with their written request to hello@stocksy.app
                    We will do our best to respond promptly and, in any event, within one month of the following:
                    Our receipt of your written request; or
                    Our receipt of any further information we may ask you to provide to enable us to comply with your
                    request, whichever is later.
                    As an alternative to account deletion, by writing to hello@stocksy.app, you also have the option to
                    request either:
                    (i) the suspension of your account, after which you will not be able to play paid contests on your
                    User account but will continue to have access to other parts of the Portal; or
                    (ii) temporary deactivation of your account, where you will no longer be able to log into the Portal
                    but which allows you to request reactivation of your account with all your account data.

                    If you proceed with permanently deactivating your account, you will lose access to the Portal and
                    the Services, including any User data and the personal information associated with your account.
                    Users may request account restoration within three (3) months from the date of notification of
                    account deletion by Stocksy by writing to hello@stocksy.app.

                    When you request the deletion of your data, we follow a deletion process that ensures that your data
                    is safely and completely removed from our servers or retained only in anonymized form. We try to
                    ensure that our services protect information from accidental or malicious deletion. Because of this,
                    there may be delays between when you request the deletion and when copies are deleted from our
                    active and backup systems.
                </p>
                <h2>Applicable Law and Jurisdiction:</h2>
                <p>By visiting this Portal, you agree that the laws of the Republic of India, without regard to its
                    conflict of laws principles, govern this Privacy Policy, and any dispute arising in respect hereof
                    shall be subject to and governed by the dispute resolution process set out in the Terms and
                    Conditions.
                </p>
                <h2>Updating Information:</h2>
                <p> You will promptly notify Stocksy if there are any changes, updates, or modifications to your
                    information. Further, you may also review, update or modify your information and user
                    preferences by
                    logging into your Profile page on the Portal.
                </p>
                <h2>Contact Us:</h2>
                <p>
                    Any questions or clarifications with respect to this Policy or any complaints, comments,
                    concerns,
                    or feedback can be sent to Stocksy at: hello@stocksy.app.
                </p>
            </section>
        </>
    );
};

export default Privacy;
