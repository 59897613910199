import React, { useState } from "react";
import "./header.scss";
import logo from "../../assets/png/stocksy-logo.png";
import { Link, Tab, Tabs } from "@mui/material";
import StockyButton from "../../components/Button";
import {useNavigate} from "react-router-dom";

const Header = () => {
  const [tab, setTab] = useState("home");
  const navigate = useNavigate();
  return (
    <header className="stocky-header">
      <div className="logo">
        <img src={logo} alt="stocksy logo" />
        <div className="stocksy">STOCKSY</div>
      </div>
      <div className="navigation">
        {/* <Link href="/">Home</Link>
        <Link href="/">About us</Link>
        <Link href="/">How to Play</Link> */}

        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          textColor="white"
          indicatorColor="secondary"
        >
          <Tab value="home" label="Home" onClick={() => { navigate('/') }}/>
          <Tab value="aboutus" label="About us" />
          <Tab value="howtoplay" label="How to Play" />
          <Tab value="privacy" label="Privacy" onClick={() => { navigate('/privacy') }}/>
        </Tabs>
        <span className="contact-us">
          <StockyButton variant="outlined" color="secondary">
            Contact Us
          </StockyButton>
        </span>
      </div>
    </header>
  );
};

export default Header;
