import React from "react";
import "./getLinkInput.scss";

const GetLinkInput = () => {
  return (
    <div className="getLinkInput-component">
      <input placeholder="Enter Phone No."/>
      <button>Get Link</button>
    </div>
  );
};

export default GetLinkInput;
