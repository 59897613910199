import React from "react";
import message from "../../assets/png/message.png";
import EmailInput from "../../common/EmailInput";

const Contactus = () => {
  return (
    <section className="home-contactus">
      <div className="info-card">
        <div>
          <h1>Stay in the loop</h1>
          <h2>Subscribe to receive the latest news and updates.</h2>
        </div>

        <div>
          <EmailInput />
        </div>
      </div>

      <div className="mail-info">
        <div className="contact">Contact</div>
        <div className="mail">
          <img src={message} /> stocksy@gmail.com
        </div>
      </div>
        <div className="mail-info">
            <div className="contact">Privacy policy</div>
            <div className="mail">
                <img src={message} /> stocksy@gmail.com
            </div>
        </div>
    </section>
  );
};

export default Contactus;
