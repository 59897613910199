import React from "react";
import spl from "../../assets/png/spl.png";
import StockyButton from "../../components/Button";

const JoinInfo = () => {
  return (
    <div className="home-joininfo">
      <img src={spl} alt="stocks premier league" />
      <div className="joininfo-description">
        <h1>What is Stock Premier League?</h1>
        <h2>Live Fantasy gaming league between Top Nifty Stocks. </h2>
        <h2>
          <StockyButton varient="text" color="secondary">
            Join Now
          </StockyButton>
          !!
        </h2>
      </div>
    </div>
  );
};

export default JoinInfo;
