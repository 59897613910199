import React from "react";
import { participants } from "./constants";
import line from "../../assets/png/left-line.png";
import money from "../../assets/png/money-hand.png";
import Succlent from "../../assets/png/Succlent angle 1.png";

const Participating = () => {
  return (
    <section className="home-team-participating">
      <h1>Teams Participating</h1>
      <img className="left-line" src={line} />
      <div className="home-participants-list">
        {participants.map((team) => (
          <div className="participant">
            <img src={team} alt="team" />
          </div>
        ))}
      </div>
      <div className="new-chanllenges">
        <img className="money-icon" src={money} />
        <h2>New Challenges everday till 12pm</h2>
        <img className="Succlent-icon" src={Succlent} />
      </div>
    </section>
  );
};

export default Participating;
