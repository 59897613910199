import React from "react";
import GetLinkInput from "../../common/GetLinkInput";

const Banner = () => {
  return (
    <section className="home-banner">
      <div className="image"></div>
      <div className="information">
        <div className="join">Join India’s first</div>
        <div className="stock">Stock Premier League</div>
        <div className="earn">And, earn from Stock Market</div>
        <div className="earn">without buying stocks!</div>
        <div className="download">
          <span>Download</span> the Stocksy App
        </div>
        <br />
        <div className="getlink">
          <GetLinkInput />
        </div>
      </div>
    </section>
  );
};

export default Banner;
