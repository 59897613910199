import './../assets/fonts/Montserrat-Black.ttf';
import "./app.scss";
import React from "react";
import Home from "./Home.js";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useMediaQuery} from "@mui/material";
import Privacy from "./Privacy";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const App = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    background: {
                        default: '#000'
                    },
                    primary: {
                        light: "#cdb562",
                        main: "#cdb562",
                        dark: "#cdb562",
                    },
                    secondary: {
                        light: "#9E3AF4",
                        main: "#9E3AF4",
                        dark: "#9E3AF4",
                    },
                    mode: prefersDarkMode ? "dark" : "light",
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            outlined: {
                                color: 'white'
                            }
                        }
                    },
                },
            }),
        [prefersDarkMode]
    );
    return (
        <ThemeProvider theme={theme}>

            <BrowserRouter>
                <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
                </Routes>
            </BrowserRouter>
            <CssBaseline/>
        </ThemeProvider>
    );
};

export default App;
