import React from "react";
import "./emailInput.scss";

const EmailInput = () => {
  return (
    <div className="emailInput-component">
      <input placeholder="Enter your email"/>
      <button>Continue</button>
    </div>
  );
};

export default EmailInput;
