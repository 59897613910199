import React from "react";
import Header from "../../common/Header";
import Banner from "./Banner";
import Participants from "./Participating";
import JoinInfo from "./JoinInfo";
import HowToPlay from "./HowToPlay";
import AppLinks from "./AppLinks";
import Contactus from "./Contactus";
import "./home.scss";

const Home = () => {
  return (
    <>
      <Header />
      <section className="stocky-home">
        <Banner />
        <JoinInfo />
        <Participants />
        <HowToPlay />
        <AppLinks />
        <Contactus />
      </section>
    </>
  );
};

export default Home;
