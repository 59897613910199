import React from "react";
import android from "../../assets/png/android.png";
import ios from "../../assets/png/ios.png";
import potly from "../../assets/png/potly.png";
import rightLine from "../../assets/png/right-line.png";
import hand from "../../assets/png/hand.png";

const AppLinks = () => {
  return (
    <div className="home-app-links">
      <h1>Get the APP and Start Winning</h1>
      <img className="hand" src={hand} alt="icon" />
      <div className="apps-list">
        <Card icon={android} title={"Android App"} description="Download our" />
        <Card icon={ios} title={"App Store"} description="Coming Soon on" />
      </div>
      <img className="potly" src={potly} alt="icon" />
      <img className="rightLine" src={rightLine} alt="icon" />
    </div>
  );
};

export default AppLinks;

const Card = ({ icon, title, description }) => {
  return (
    <div className="app-link-card">
      <img src={icon} alt="icon" />
      <div className="info">
        <div className="desc">{description}</div>
        <div className="title">{title}</div>
      </div>
    </div>
  );
};
