import team1 from "../../assets/png/Mask group-1.png";
import team2 from "../../assets/png/Mask group-2.png";
import team3 from "../../assets/png/Mask group-3.png";
import team4 from "../../assets/png/Mask group-4.png";
import team5 from "../../assets/png/Mask group-5.png";
import team6 from "../../assets/png/Mask group-6.png";
import team7 from "../../assets/png/Mask group-7.png";
import team8 from "../../assets/png/Mask group-8.png";
import team9 from "../../assets/png/Mask group-9.png";
import team10 from "../../assets/png/Mask group-10.png";

export const participants = [
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  team9,
  team10,
];
